<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9;">
		<BHLoading :show="loading" />
		<div class="dF jSB">
			<div class="dF aC mb-3 mt-3 mail-category">
				<h4>{{ sidebarSelection }}</h4>
				<a-tooltip title="Get new mail" overlayClassName="change-tooltip-color">
					<a-icon @click="cacheAllThreadMessages = {}; getMailData()"
						class="mail-reload ml-2 cursor-pointer hover:text-primary"
						style="position: relative;top: -2px;font-size: 14px;" type="reload" />
				</a-tooltip>
			</div>
		</div>

		<div>
			<a-tabs v-model="selectedTab" @change="selectMail.show = false; selectMail.mail === null" size="large">
				<a-tab-pane key="Integrated Account">
					<span slot="tab">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Only emails from the integrated email account will be displayed for both outgoing and
								incoming messages.
							</template>
							Integrated Account
							<a-icon type="question-circle" style="font-size: 12px" class="ml-1" />
						</a-tooltip>
					</span>
				</a-tab-pane>
				<a-tab-pane key="Sent History" v-if="$p >= 20">
					<span slot="tab">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								View all email threads sent from Bildhive's CRM from any user.
							</template>
							Sent History
							<a-icon type="question-circle" style="font-size: 12px" class="ml-1" />
						</a-tooltip>
					</span>
				</a-tab-pane>
			</a-tabs>
		</div>
		<template v-if="selectedTab === 'Integrated Account'">
			<a-row v-if="emailList.length" type="flex" class="w-full" style="height: 75vh;">
				<!-- List of All Messages -->
				<div class="hide-scrollbar left-email-bar" style="height: 100%;">
					<a-card class="mailHolder hide-scrollbar"
						style="overflow: scroll; height: calc(100% - 65px); cursor: pointer;">
						<div v-for="(mail) in emailList" :key="mail.id">
							<mailCard :sent="true" :key="mail.id" @showemail="showEmail" :email="mail"
								:current="selectMail.mail" @updateLoading="updateLoading"
								@removeThread="removeThread" />
						</div>
						<template v-if="!loading && !maxLoaded">
							<Observed @observed="loadMore" v-if="next_page_cursor" :key="moreLoaded">
								<div class="py-3 dF fC aC jC" style="background:#eee;">
									<a-icon type="loading" /> Loading
								</div>
							</Observed>
						</template>
					</a-card>
				</div>

				<div class="right-email-bar">
					<!-- If No Message Selected -->
					<a-card v-if="!selectMail.show" class="mailContent relative w-full h-full" />
					<!-- If Message Selected -->
					<div v-else class="w-full h-full">
						<!-- Message Content -->
						<mailContent :email="selectMail.mail" />
					</div>
				</div>
			</a-row>

			<template v-else>
				<EmptyApp
					v-if="(vendors.gmail && vendors.gmail.connectUrl && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active) || (vendors.outlook && vendors.outlook.connectUrl && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active) || (vendors.office365 && vendors.office365.connectUrl && vendors.office365.hasOwnProperty('active') && vendors.office365.active)"
					:text="'You do not have any sent emails'" :img="require('@/assets/emptyIcons/email.png')"
					:app="$store.state.theApp" />
				<EmptyApp v-else
					:text="'You haven\'t connected your email account. To connect, go to Settings and select Email & Calendar Settings > Integrations.'"
					:img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
			</template>
		</template>
		<template v-else-if="selectedTab === 'Sent History'">
			<a-row v-if="storedMail.length" type="flex" class="w-full" style="height: 75vh;">
				<!-- List of All Messages -->
				<div class="hide-scrollbar left-email-bar" style="height: 100%;">
					<a-card class="mailHolder hide-scrollbar"
						style="overflow: scroll; height: calc(100% - 65px); cursor: pointer;">
						<div v-for="(mail) in storedMail" :key="mail.id">
							<mailCard :key="mail.id" :stored="true" @showemail="showEmail" :email="mail"
								:current="selectMail.mail" @updateLoading="updateLoading"
								@removeThread="removeThread" />
						</div>
						<template v-if="!loading && !maxStoredMailLoaded">
							<Observed @observed="loadMoreStoredMail" v-if="storedMail.length < storedMailCount"
								:key="moreLoaded">
								<div class="py-3 dF fC aC jC" style="background:#eee;">
									<a-icon type="loading" /> Loading
								</div>
							</Observed>
						</template>
					</a-card>
				</div>

				<div class="right-email-bar">
					<!-- If No Message Selected -->
					<a-card v-if="!selectMail.show" class="mailContent relative w-full h-full" />
					<!-- If Message Selected -->
					<div v-else class="w-full h-full">
						<!-- Message Content -->
						<storedMailContent :email="selectMail.mail" />
					</div>
				</div>
			</a-row>

			<template v-else>
				<template v-if="!(nylasAccount && nylasAccount.email)">
					<EmptyApp
						:text="'You haven\'t connected your email account. To connect, go to Settings and select Email & Calendar Settings > Integrations.'"
						:img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
				</template>
				<template v-else>
					<EmptyApp :text="'You do not have any sent email history'"
						:img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
				</template>
			</template>
		</template>
	</div>
</template>

<script>
	import BHLoading from 'bh-mod/components/common/Loading'
	import EmptyApp from 'bh-mod/components/common/EmptyApp'
	import mailCard from '@/components/common/mailCard'
	import mailContent from '@/components/common/mailContent'
	import storedMailContent from '@/components/common/storedMailContent'
	import MoreActions from 'bh-mod/components/common/MoreActions'
	import Observed from '@/components/common/Observed'

	export default {
		components: {
			BHLoading, EmptyApp, mailCard, mailContent, MoreActions, Observed, storedMailContent
		},
		data() {
			return {
				maxLoaded: false,
				maxStoredMailLoaded: false,
				loading: false,
				selectedTab: 'Integrated Account',
				email: {
					to: [],
					from: {},
					reply: '',
				},
				selectMail: {
					mail: {},
					show: false,
				},
				showReply: {
					show: false,
					mail: {},
				},
				storedMail: [],
				storedMailCount: 0,
				currentPage: 1,
				pageSize: 10,
				cacheAllThreadMessages: {}
			}
		},

		computed: {
			vendors() {
				return this.$store.state.contacts.vendors || {}
			},
			moreLoaded() {
				return this.$store.state.contacts.sentMoreLoaded
			},
			lastDate() {
				if (!this.emailList.length) return 0
				let last = this.emailList[0]
				let lastMessage = last.messages[last.messages.length - 1]
				return (new Date(lastMessage.date).getTime() / 1000)
			},
			next_page_cursor() {
				return this.$store.state.contacts.sent_next_page_cursor
			},
			emailList() {
				return this.$store.state.contacts.sent
			},
			sidebarSelection() {
				return this.$store.state.sidebarSelection
			},
			instance() {
				return this.$store.state.instance
			},
			nylasAccount() {
				return this.$store.state.contacts.nylasAccount || {}
			}
		},
		methods: {
			removeThread(data) {
				let index = this.emailList.findIndex(x => x.id == data.id)
				if (index != -1) {
					this.emailList.splice(index, 1)
				}
			},
			updateLoading(load) {
				this.loading = load;
			},
			getMail() {
				let lastDate = this.lastDate
				this.loading = true
				this.$api.get(`/contacts/:instance/sent/v3?`).then(({ data }) => {
					if (data.hasOwnProperty('success') && !data.success) {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your Gmail account.',
							duration: 5
						});
					} else {
						if (lastDate) {
							this.$store.commit('NEW_SENT', data.data)
						} else {
							this.$store.commit('STORE_SENT', data.data)
						}
						this.$store.commit('STORE_SENT_NEXT_PAGE_CURSOR', data.next_page_cursor)
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},
			loadMore() {
				this.$api.get(`/contacts/:instance/sent/v3?page_token=${this.next_page_cursor}`).then(({ data }) => {
					if (data.hasOwnProperty('success') && !data.success) {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your Gmail account.',
							duration: 5
						});
					} else {
						if (data?.data?.length) {
							this.$store.commit('UPDATE_SENT', data.data)
							this.$store.commit('INCREASE_SENT_MORE_LOADED')
						} else if (!data?.next_page_cursor) {
							this.maxLoaded = true
						}
						this.$store.commit('STORE_SENT_NEXT_PAGE_CURSOR', data.next_page_cursor)
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},
			async loadMoreStoredMail() {
				this.currentPage++;
				await this.getStoredMail();
				this.$store.commit('INCREASE_SENT_MORE_LOADED')
			},

			async showEmail(obj) {
				if (obj != this.selectMail.mail) {
					this.showReply = {
						show: false,
						mail: {}
					}
				}
				if (this.selectedTab === 'Sent History') {
					this.selectMail.mail = obj
					this.selectMail.show = true
					return
				}

				obj.messages = await this.getAllMessages(obj.id);

				if (obj.unread) {
					this.$api.put(`/contacts/:instance/threads/${obj.id}/read`).then(({ data }) => {
						obj.unread = false
						this.selectMail.mail = obj
						this.selectMail.show = true
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
				} else {
					this.selectMail.mail = obj
					this.selectMail.show = true
				}
			},

			async getStoredMailCount() {
				try {
					const { data } = await this.$api.get(`email-messages/:instance/count`)
					this.storedMailCount = data
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching sent email history count. Please try again.`))
					}
				}
			},

			async getStoredMail() {
				try {
					if (this.storedMail.length === this.storedMailCount && this.currentPage !== 1) {
						this.maxStoredMailLoaded = true;
						return;
					}
					let start = (this.currentPage - 1) * this.pageSize;
					const { data } = await this.$api.get(`email-messages/:instance?_start=${start}&_limit=${this.pageSize}&_sort=createdAt:DESC`)
					this.storedMail.push(...data);
					if (this.storedMail.length === this.storedMailCount) {
						this.maxStoredMailLoaded = true;
					}
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching sent email history. Please try again.`))
					}
				}
			},

			// Fetch all the message for the selected mail as new v3 API not giving all the message
			async getAllMessages(thread_id) {
				if (this.cacheAllThreadMessages[thread_id]) {
					return this.cacheAllThreadMessages[thread_id]
				}
				let messages = [];
				let next_page_cursor = null;
				do {
					try {
						this.loading = true
						let page_token = next_page_cursor ? `&page_token=${next_page_cursor}` : ''
						const { data } = await this.$api.get(`/contacts/:instance/messages?thread_id=${thread_id}${page_token}`)
						if (data?.data?.length) {
							messages.push(...data.data);
						}
						next_page_cursor = data.next_page_cursor;
						this.loading = false
					} catch (err) {
						this.loading = false;
						next_page_cursor = null;
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
				} while (next_page_cursor);

				this.cacheAllThreadMessages[thread_id] = messages.reverse()

				return this.cacheAllThreadMessages[thread_id];
			},

			getMailData() {
				this.storedMailCount = 0;
				this.currentPage = 1;
				this.storedMail = [];
				this.maxStoredMailLoaded = false;
				this.$store.commit('STORE_SENT_NEXT_PAGE_CURSOR', null)
				if (this.nylasAccount?.email) {
					this.getMail()
				}
				this.getStoredMailCount();
				this.getStoredMail();
			}
		},
		created() {
			this.getMailData()
			this.$store.commit('SIDEBAR_SELECTION', { value: 'Sent' })
		}
	}
</script>
<style lang="scss" module>
	@import "./style.module.scss";
</style>
<style lang="scss" scoped>
	.left-email-bar {
		width: 280px;

		@media screen and (min-width: 1300px) {
			width: 400px;
		}
	}

	.right-email-bar {
		flex: 1;
		padding: 30px;
		border: 1px solid #e8e8e8;
		border-left: none;
		height: 100%;
		background-color: #FAF9F9
	}

	.contacts-page {
		@media screen and (min-width:567px) {
			margin-top: 5%;
		}
	}

	.aB {
		align-items: baseline;
	}

	.mail-reload {
		opacity: 1;
	}

</style>

<style lang="scss">
	.mailHolder {
		height: 100%;

		.ant-card-body {
			padding: 0;
		}
	}

	.mailHolder.ant-card.ant-card-bordered {
		border: none;
	}

	.mailContent .ant-card-body {
		padding: 48px;
	}

	.replyBox {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;

		&:focus {
			border-color: #d9d9d9;
			border-right-width: 0;
			box-shadow: none;
			-webkit-box-shadow: none;
		}

		&:hover {
			border-color: #d9d9d9;
		}
	}
</style>
